@font-face {
    font-family: 'Sharp Sans';
    src: url('./fonts/SharpSans-Semibold.woff2') format('woff2'),
    url('./fonts/SharpSans-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std C';
    src: url('./fonts/UniversLTStd-UltraCn.woff2') format('woff2'),
    url('./fonts/UniversLTStd-UltraCn.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std B';
    src: url('./fonts/UniversLTStd-BoldCn.woff2') format('woff2'),
    url('./fonts/UniversLTStd-BoldCn.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('./fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
    url('./fonts/AvenirNextLTPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('./fonts/AvenirNextLTPro-Bold.woff2') format('woff2'),
    url('./fonts/AvenirNextLTPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "ThirstySoftRegular-Bold";
    src: url('./fonts/font.woff2') format('woff2'), url('./fonts/font.woff') format('woff');
}

@font-face {
    font-family: 'DroneRanger01';
    src: url('./fonts/drone-ranger-01.eot');
    src: url('./fonts/drone-ranger-01.eot?#iefix') format('embedded-opentype'),
    url('./fonts/drone-ranger-01.woff2') format('woff2'),
    url('./fonts/drone-ranger-01.woff') format('woff'),
    url('./fonts/drone-ranger-01.ttf') format('truetype'),
    url('./fonts/drone-ranger-01.svg#youworkforthem') format('svg');
}
html, body {
    height: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    background: url("./img/bg.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

#root {
    height: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
}

.App {
    height: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-style: normal;
}

.App2 {
    min-height: 100%;
    min-width: 100%;
    padding: 0;
    margin: 0;
}

.transmiddle{
  /*  min-height: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    padding:20px;
    //width:75%;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #fff;
    background-color: rgba(255, 255, 255, 0.8);
*/
}

.Main-Container {
    min-height: 100%;
    min-width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.Main-Center {
    min-height: 100%;
    padding: 0;
    margin: 0;
    justify-content: right;
    align-items: flex-start;
}

.Main-Right {
    min-height: 100%;
    padding: 0;
    margin: 0;
    flex-direction: column;
    justify-content: center;
    align-content: flex-end;
    align-items: flex-end;
}

.Main-Left {
    //visibility: hidden;
    //display: flex;
    //height: 100%;
    //max-width: 100%;
    //padding: 0;
    //margin: 0;
    //display: flex;
    //justify-content: space-around;
    //align-content: flex-end;
    //align-items: flex-start;
    //overflow: hidden;
}


.Logo-Image{
    margin: 0px;
    margin: auto;
    padding-bottom: 20px;
    padding-top: 20px;
    max-height: 200px;
}

.Product-Image {
    padding: 0;
    margin: 0;
    margin: auto;
    margin-left:10px;
    max-width: 30vw;
    bottom: 5vh;
    left: 1vw;
    position: fixed;
    border-color: #FFFFFF;
}

.Product2-Image {
    padding: 0;
    margin: 0;
    height: 200px;
    border: 3px solid black;
    border-radius: 30px;
    border-color: #FFFFFF;
}

.Bracket-Image {
    padding: 0;
    margin: 0;
    bottom: 50vh;
    max-width: 100%;
    position: fixed;
    transform: scaleX(-1);
    left: -5vw;
}

.Bracket-Image2 {
    padding: 0;
    margin: 0;
    bottom: 5vh;
    max-width: 100%;

}

.Tag-Image{
    padding: 0;
    margin: 0;
    max-width: 250px;
    position: fixed;
    top: 80vh;
    right: 60px;
}

.Tag-Image2{
    padding: 0;
    margin: 0;
    max-width: 450px;
    position: fixed;
    top: 0vh;
    right: -30px;
}

.universec33{
    font-family: Univers LT Std C, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: .98;
    font-size: calc(20px + 2vmin);
    color: #333333;
}

.universec28{
    font-family: Univers LT Std C, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: .98;
    font-size: calc(17px + 2vmin);
    color: #333333;
}
.universeb28{
    font-family: Univers LT Std B, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: .98;
    font-size: calc(17px + 2vmin);
    color: #333333;
}

.universec59{
    font-family: Univers LT Std B, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: .98;
    font-size: calc(57px + 2vmin);
    color: #333333;
}

.sharp36{
    font-family: Sharp Sans, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: .98;
    font-size: calc(23px + 2vmin);
    color: #333333;
}

.sharp71{
    font-family: Sharp Sans, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: .98;
    font-size: calc(71px + 2vmin);
    color: #333333;
}
.thirstyBold43{
    font-family: ThirstySoftRegular-Bold, sans-serif;
    padding: 0;
    margin: 0;
    font-size: calc(22px + 1vmin);
    color: #3e3e3e;
    line-height: .92;
}

.drone33{
    font-family: DroneRanger01, sans-serif;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    font-size: calc(22px + 1vmin);
    color: #3e3e3e;
    line-height: .92;
    text-shadow: 2px 2px 4px #000000;
}

.drone25{
    font-family: DroneRanger01, sans-serif;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    font-size: calc(10px + 1vmin);
    color: #3e3e3e;
    line-height: .92;
    text-shadow: 2px 2px 4px #000000;
}

.drone30{
    font-family: DroneRanger01, sans-serif;
    padding: 0;
    margin: 0;
    font-size: calc(20px + 1vmin);
    color: #3e3e3e;
    line-height: .92;
    text-shadow: 2px 2px 4px #000000;
}

.drone50{
    font-family: DroneRanger01, sans-serif;
    padding: 0;
    margin: 0;
    font-size: calc(35px + 1vmin);
    color: #3e3e3e;
    line-height: .92;
    text-shadow: 2px 2px 4px #000000;
}

.drone71{
    font-family: DroneRanger01, sans-serif;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    font-size: calc(45px + 1vmin);
    color: #3e3e3e;
    line-height: .92;
    text-shadow: 2px 2px 4px #000000;
}

.avenirBold82{
    font-family: Avenir Next LT Pro, sans-serif;
    font-weight: 900;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    font-size: calc(60px + 1vmin);
    color: #3e3e3e;
    line-height: .92;
    text-shadow: 2px 2px 4px #000000;
}

.avenirBold16{
    font-family: Avenir Next LT Pro, sans-serif;
    font-weight: 600;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    font-size: calc(5px + 1vmin);
    color: #3e3e3e;
    line-height: .92;
    text-shadow: 2px 2px 4px #000000;
}

.avenirBold31{
    font-family: Avenir Next LT Pro, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    font-size: calc(25px + 1vmin);
    color: #3e3e3e;
    line-height: .92;
    text-shadow: 2px 2px 4px #000000;
}

.novaBold26_28{
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: .92;
    font-size: calc(18px + 2vmin);
    color: #333333;
    text-shadow: 2px 2px 4px #000000;
}

.novaBold65_66{
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: .98;
    font-size: calc(57px + 2vmin);
    color: #333333;
    text-shadow: 2px 2px 4px #000000;
}

.novaBold26_45{
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: .58;
    font-size: calc(18px + 2vmin);
    color: #333333;
    text-shadow: 2px 2px 4px #000000;
}
.novaBold40_45{
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: .89;
    font-size: calc(32px + 2vmin);
    color: #333333;
    text-shadow: 2px 2px 4px #000000;
}

.novaBold43_47{
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: .91;
    font-size: calc(35px + 2vmin);
    color: #333333;
    text-shadow: 2px 2px 4px #000000;
}

.novaBold18_20{
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: .9;
    font-size: calc(10px + 2vmin);
    color: #333333;
    text-shadow: 2px 2px 4px #000000;
}

.roboBold18{
    font-family: roboto, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 120%;
    font-size: calc(10px + 2vmin);
    color: #333333;
    text-shadow: 2px 2px 4px #000000;
}

.roboBlack24{
    font-family: roboto, sans-serif;
    font-weight: 900;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 120%;
    font-size: calc(16px + 2vmin);
    color: #333333;
    text-shadow: 2px 2px 4px #000000;
}

.roboMed9_11{
    font-family: roboto, sans-serif;
    font-weight: 900;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: .82;
    font-size: calc(2px + 1vmin);
    color: #011698;
}

.roboMed15{
    font-family: roboto, sans-serif;
    font-weight: 900;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    font-size: calc(4px + 1vmin);
    color: #011698;
}
.ultrablue{
    color: #011689;
}
.ultrayellow{
    color: #eba904;
}

.ultrawhite{
    color: #FFFFFF;
}

.centertopmargin{
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 120%;
    font-size: calc(8px + 2vmin);
    color: #000033;
    padding-top: 100px;
}

.centerbottommargin{
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 120%;
    font-size: calc(12px + 2vmin);
    color: #000033;
    padding-bottom: 50px;
}

/*1000 Large 768 Medium*/
@media (min-width:0px) and (max-width: 768px) {
.Tag-Image{
    padding: 0;
    margin: 0;
    position: unset;
    max-height: 800px;
}

.Product-Image {
    padding: 0;
    margin: 0;
    position: unset;
    padding-bottom: 10px;
    max-height: 500px;
}

    .Product2-Image {
        padding: 0;
        margin: 0;
        position: unset;
        max-height: 200px;
    }

.Logo-Image{
    max-height: 150px;
    padding-left: 15px;
    padding-right:15px;
}

  /*  .Main-Center {
        min-height: 100%;
        max-width: 80%;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: right;
        align-items: flex-start;
    }
*/
    .Top-Mobile {
        margin-bottom: 5px;
    }
}

.vertical-center {
    align-items: center;
}

.invalid-feedback-block {
    display: block;
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 120%;
    font-size: calc(6px + 2vmin);
    color: #fff;
}

.Sucess-Button {
    min-width: 50%;
    min-height: 40px;
    margin: 10px;
    font-family: sweet-gothic, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    line-height: 100%;
    font-size: calc(8px + .8vmin);
    letter-spacing: 1px;
    color: white;
    border-radius: 10px;
    text-decoration: none;
    border-color: white;
    background: rgb(1,22,137);
    background: linear-gradient(331deg, rgba(1,22,137,0.6802325581395349) 12%, rgba(1,22,137,1) 50%, rgba(1,22,137,0.686046511627907) 99%);
}

.Sucess-Button:hover {
    background: rgb(1,22,137);
    background: linear-gradient(331deg, rgba(1,22,137,0.6802325581395349) 12%, rgba(1,22,137,1) 50%, rgba(1,22,137,0.686046511627907) 99%);
}

.btn-link {
    margin: 10px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    line-height: 100%;
    font-size: calc(6px + .5vmin);
    color: white;
}




form {
    padding: 0;
    margin: 0;
    height: 100%;
    max-width: 100%;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
    font-size: calc(8px + .1vmin);
    color: #00873e;
    text-align: center;
}

form .form-check {
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    font-size: calc(13px + .1vmin);
    color: #000000;
}

/*form .btn-primary {
    padding:1px;
    margin: 0;
    min-width: 200px;
    font-family: Roboto, sans-serif;
    font-weight: 900;
    font-style: normal;
    text-transform: uppercase;
    line-height: 200%;
    font-size: calc(20px + .5vmin);
    border-radius: 15px;
    text-decoration: none;
}*/


.File-Form-Container{
    max-width: 100%;
    align-self: center;
    justify-content: center;
}

.Age-Form-Container{
    max-width: 100%;
    height: 100%;
    align-self: center;
    justify-content: center;
}

.Age-Select{
    font-family: roboto, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 120%;
    font-size: calc(10px + 2vmin);
    color: #000000;
    border-color:  #000000;
    border-width: 2px;
    min-width: 150px;
    text-align: center;
}

.Age-Button{
    padding:1px;
    margin: 0;
    min-width: 200px;
    font-family: Roboto, sans-serif;
    font-weight: 900;
    font-style: normal;
    text-transform: uppercase;
    line-height: 200%;
    font-size: calc(16px + .5vmin);
    color: white;
    border-radius: 10px;
    border-color: white;
    text-decoration: none;
    background: rgb(1,22,137);
    background: linear-gradient(331deg, rgba(1,22,137,0.6802325581395349) 12%, rgba(1,22,137,1) 50%, rgba(1,22,137,0.686046511627907) 99%);
}

.Age-Button:hover{
    background: rgb(1,22,137);
    background: linear-gradient(331deg, rgba(1,22,137,0.6802325581395349) 12%, rgba(1,22,137,1) 50%, rgba(1,22,137,0.686046511627907) 99%);
}


.Name-Form-Container{
    max-width: 100%;
    align-self: center;
    justify-content: center;
}

.Name-Text{
    border-color: #000000;
    border-width: 2px;
    min-width: 200px;
}
.Name-Select{
    border-color: #000000;
    border-width: 2px;
    min-width: 200px;
}

.Upload-Button{
  padding:1px;
    margin: 0;
    min-width: 200px;
    font-family: Roboto, sans-serif;
    font-weight: 900;
    font-style: normal;
    text-transform: uppercase;
    line-height: 200%;
    font-size: calc(20px + .5vmin);
    color: white;
    border-radius: 10px;
    border-color: white;
    text-decoration: none;
    background: rgb(1,22,137);
    background: linear-gradient(331deg, rgba(1,22,137,0.6802325581395349) 12%, rgba(1,22,137,1) 50%, rgba(1,22,137,0.686046511627907) 99%);
}

.Upload-Button:hover{
    background: rgb(1,22,137);
    background: linear-gradient(331deg, rgba(1,22,137,0.6802325581395349) 12%, rgba(1,22,137,1) 50%, rgba(1,22,137,0.686046511627907) 99%);
}

.Name-Button{
    padding:1px;
    margin: 0;
    min-width: 200px;
    font-family: Roboto, sans-serif;
    font-weight: 900;
    font-style: normal;
    text-transform: uppercase;
    line-height: 200%;
    font-size: calc(16px + .5vmin);
    color: white;
    border-radius: 10px;
    border-color: white;
    text-decoration: none;
    background: rgb(1,22,137);
    background: linear-gradient(331deg, rgba(1,22,137,0.6802325581395349) 12%, rgba(1,22,137,1) 50%, rgba(1,22,137,0.686046511627907) 99%);
}

.Name-Button:hover{
    background: rgb(1,22,137);
    background: linear-gradient(331deg, rgba(1,22,137,0.6802325581395349) 12%, rgba(1,22,137,1) 50%, rgba(1,22,137,0.686046511627907) 99%);
}

.Container-Disclaimer {
    min-height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: right;
    align-items: flex-start;
}

.App-Disclaimerb {
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-style: normal;
    margin: 0px;
    padding: 0px;
    line-height: 120%;
    color: #011698;
    font-size: calc(8px + .3vmin);
}

.linkbtn {
    margin: 10px;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    line-height: 100%;
    font-size: calc(6px + .5vmin);
    color: #011698;
    text-decoration: none;
}

.App-Disclaimer {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-style: normal;
    margin: 0px;
    padding: 0px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right:20px;
    line-height: 120%;
    color: #011698;
    font-size: calc(8px + .3vmin);
}
